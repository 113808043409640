<template>
    <div class="card">
        <div class="flex justify-content-between">
            <div class="flex align-items-center">
                <img src="../../assets/img/contrato.png" style="width: 2.3rem; margin-right: 0.5rem;"/>
                <h3 style="margin:0px 5px;">{{ id ? 'Editar' : 'Cadastrar'}} Contrato Jurídico</h3>
            </div>  
        </div>

        <hr />

        <div class="grid p-fluid formgrid mt-3">
            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label>
                        <strong>Nro.Contrato Interno<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    
                    <ContratoZoom
                        codigo="contratoInternoId"
                        descricao="nome"
                        :selecionado="dados.contrato"
                        :disabled="false"
                        :invalid="campoInvalido.contrato"
                        :paginacao="contratoConsulta"
                        :pesquisaContratoJuridico="false"
                        @pesquisar="pesquisarContrato"
                        @selecionado="selecionarContrato"
                    />
                </div>
            </div>

            <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="modalidade">
                        <strong>Modalidade</strong>
                    </label>
                    <InputText v-model="dados.modalidade" id="modalidade" :disabled="true" />
                </div>
            </div>

            <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="moeda">
                        <strong>Moeda</strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..." 
                        dropdown
                        v-model="dados.moeda"
                        field="descricao"
                        class="w-full"
                        id="moeda"
                        :suggestions="moedaFiltro"
                        :forceSelection="true"
                        @complete="buscarMoedas"
                    />
                </div>
            </div>
        </div>
                
        <Fieldset legend="Condição de Pagamento">
            <div class="flex flex-wrap gap-3">
                <div class="flex align-items-center mr-3 mt-2 mb-2" v-if="dados.modalidade !== 'Preço A Fixar'">
                    <RadioButton v-model="dados.tipo" id="fixo-integral-dia" value="FIXO_INTEGRAL_DIA" />
                    <label for="fixo-integral-dia" class="ml-2">Fixo e Integral no Dia</label>
                </div>
                <div class="flex align-items-center mr-3 mt-2 mb-2" v-if="dados.modalidade !== 'Preço A Fixar'">
                    <RadioButton v-model="dados.tipo" id="dias-embarque" value="DIAS_EMBARQUE" />
                    <label for="dias-embarque" class="ml-2">Dias do Embarque</label>
                </div>
                <div class="flex align-items-center mr-3 mt-2 mb-2" v-if="dados.modalidade !== 'Preço A Fixar'">
                    <RadioButton v-model="dados.tipo" id="dias-desembarque" value="DIAS_DESEMBARQUE" />
                    <label for="dias-desembarque" class="ml-2">Dias do Desembarque</label>
                </div>
                <div class="flex align-items-center mr-3 mt-2 mb-2" v-if="dados.modalidade === 'Preço A Fixar'">
                    <RadioButton v-model="dados.tipo" id="a-prazo-em-comum-acordo" value="A_PRAZO_EM_COMUM_ACORDO" />
                    <label for="a-prazo-em-comum-acordo" class="ml-2">A Prazo em Comum Acordo entre as Partes</label>
                </div>
                <div class="flex align-items-center mr-3 mt-2 mb-2" v-if="dados.modalidade === 'Preço A Fixar'">
                    <RadioButton v-model="dados.tipo" id="dias-fixacao" value="DIAS_FIXACAO" />
                    <label for="dias-fixacao" class="ml-2">Dias da Fixação</label>
                </div>
                <div class="flex align-items-center mr-3 mt-2 mb-2">
                    <RadioButton v-model="dados.tipo" id="parcelas" value="PARCELAS" />
                    <label for="parcelas" class="ml-2">Parcelas</label>
                </div>
                <div class="flex align-items-center mr-3" mt-2 mb-2>
                    <RadioButton v-model="dados.tipo" id="outros" value="OUTROS" />
                    <label for="outros" class="ml-2">Outros</label>
                </div>

                <div class="flex align-items-center mr-3" v-if="dados.tipo === 'DIAS_EMBARQUE' || dados.tipo === 'DIAS_DESEMBARQUE' || dados.tipo === 'DIAS_FIXACAO'">
                    <label class="mr-2" for="nro-dias">Nro. Dias:</label>
                    <InputNumber  v-model="dados.nroDias" id="nro-dias"/>
                </div>

                <div class="flex align-items-center mr-3" style="width: 30rem;" v-if="dados.tipo === 'OUTROS'">
                    <label class="mr-2" for="outros">Outros:</label>
                    <InputText class="w-full" v-model="dados.outros" id="outros"/>
                </div>
                
                <div class="flex align-items-center mr-3" v-if="dados.tipo === 'FIXAO_INTEGRAL_NO_DIA'">
                    <label class="mr-2" for="data_vencimento">Data Vencimento:</label>
                    <Calendar
                        v-model="dados.dataVencimento"
                        id="data_vencimento"
                        dateFormat="dd/mm/yy"
                    />
                </div>
            </div>
        </Fieldset>
                
        <div class="grid p-fluid formgrid mt-3">

            <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="padrao-contrato">
                        <strong>Padrão Contrato<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..." 
                        dropdown
                        v-model="dados.padraoContrato"
                        field="descricao"
                        class="w-full"
                        id="padrao-contrato"
                        :class="{ 'p-invalid': campoInvalido.padraoContrato }"
                        :suggestions="padroesContratoFiltro"
                        :forceSelection="true"
                        @complete="buscarPadroesContrato"
                    />
                </div>
            </div>

            <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="emite-contrato-fixacao">
                        <strong>Emite Cont. para cada Fixação<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..." 
                        dropdown
                        v-model="dados.emiteContratoParaCadaFixacao"
                        field="descricao"
                        class="w-full"
                        id="emite-contrato-fixacao"
                        :class="{ 'p-invalid': campoInvalido.emiteContratoParaCadaFixacao }"
                        :suggestions="simOuNaoFiltro"
                        :forceSelection="true"
                        @complete="buscarSimOuNao"
                    />
                </div>
            </div>

            <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="contrato-gerado">
                        <strong>Contrato Gerado</strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..." 
                        dropdown
                        v-model="dados.contratoGerado"
                        field="descricao"
                        class="w-full"
                        id="contrato-gerado"
                        :suggestions="simOuNaoFiltro"
                        :forceSelection="true"
                        @complete="buscarSimOuNao"
                    />
                </div>
            </div>

            <div class="col-6 sm:col-3 md:col-3 lg:col-3 xl:col-3">
                <div class="field">
                    <label for="situacao">
                        <strong>Situação</strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..." 
                        dropdown
                        v-model="dados.situacao"
                        field="descricao"
                        class="w-full"
                        id="situacao"
                        :suggestions="situacaoFiltro"
                        :forceSelection="true"
                        @complete="buscarSituacoes"
                    />
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label for="descritivo-cobranca-armazenagem">
                        <strong>Descritivo da Cobrança de Armazenagem</strong>
                    </label>
                    <Textarea v-model="dados.descritivoCobrancaArmazenagem" id="descritivo-cobranca-armazenagem" style="resize: none;"></Textarea>
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label for="descritivo-conversao-moeda">
                        <strong>Descritivo da Conversão Moeda</strong>
                    </label>
                    <Textarea v-model="dados.descritivoConversaoMoeda" id="descritivo-conversao-moeda" style="resize: none;"></Textarea>
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label for="outras-condicoes">
                        <strong>Outras Condições</strong>
                    </label>
                    <Textarea v-model="dados.outrasCondicoes" id="outras-condicoes" style="resize: none;"></Textarea>
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label for="descritivo-preco">
                        <strong>Descritivo de Preço</strong>
                    </label>
                    <Textarea v-model="dados.descritivoPreco" id="descritivo-preco" style="resize: none;"></Textarea>
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label for="descritivo-vencimento">
                        <strong>Descritivo Vencimento</strong>
                    </label>
                    <Textarea v-model="dados.descritivoVencimento" id="descritivo-vencimento" style="resize: none;"></Textarea>
                </div>
            </div>

            <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6">
                <div class="field">
                    <label for="descritivo-fixacao">
                        <strong>Descritivo Fixação</strong>
                    </label>
                    <Textarea v-model="dados.descritivoFixacao" id="descritivo-fixacao" style="resize: none;"></Textarea>
                </div>
            </div>
        </div>

        <div class="mt-3 borda-personalizada">
            <div class="flex align-items-center mr-3">
                <Checkbox inputId="emite-nota-promissoria" v-model="dados.emiteNotaPromissoria" :binary="true" :disabled="true" />
                <label for="emite-nota-promissoria" class="ml-2">Emite Nota Promissória</label>
            </div>

            <div class="grid p-fluid formgrid mt-3">
                <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2">
                    <div class="field">
                        <label for="data-emissao">
                            <strong>Data Emissão</strong>
                        </label>
                        <InputText id="data-emissao" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2">
                    <div class="field">
                        <label for="data-vencimento">
                            <strong>Data Vencimento</strong>
                        </label>
                        
                        <InputText id="data-vencimento" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2">
                    <div class="field">
                        <label for="valor">
                            <strong>Valor</strong>
                        </label>
                        
                        <InputText id="valor" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2">
                    <div class="field">
                        <label for="imprime-nr-contrato">
                            <strong>Imprime Nro. Contrato</strong>
                        </label>
                        
                        <InputText id="imprime-nr-contrato" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-4">
                    <div class="field">
                        <label for="praca-pagamento">
                            <strong>Praça Pagamento</strong>
                        </label>

                        <InputText id="praca-pagamento" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-4">
                    <div class="field">
                        <label for="assinatura-1">
                            <strong>Assinatura 1</strong>
                        </label>

                        <InputText id="assinatura-1" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-4">
                    <div class="field">
                        <label for="assinatura-2">
                            <strong>Assinatura 2</strong>
                        </label>

                        <InputText id="assinatura-2" :disabled="true" />
                    </div>
                </div>
            </div>
        </div>
        
        <ContratoJuridicoFielDepositario :dados="dados" :campo-invalido="campoInvalido" />
        <ContratoJuridicoGaranteSolidario :dados="dados" :campo-invalido="campoInvalido" />

        <hr />

        <div class="flex">
            <Button
                icon="pi pi-save"
                label="Salvar"
                class="p-button-info"
                @click="salvar"
            ></Button>

            <Button
                icon="pi pi-times"
                label="Cancelar"
                class="p-button-danger ml-2"
                @click="voltar"
            ></Button>
        </div>
    </div>
</template>

<script>
import ContratoZoom from '../zoom/ContratoZoom.vue';
import ContratoJuridicoService from '../../service/ContratoJuridicoService';
import ContratoCompraVendaService from '../../service/ContratoCompraVendaService';
import ContratoJuridicoFielDepositario from './ContratoJuridicoFielDepositario.vue';
import ContratoJuridicoGaranteSolidario from './ContratoJuridicoGaranteSolidario.vue';
import StorageService from '../../service/StorageService';

export default {
    components: { ContratoZoom, ContratoJuridicoFielDepositario, ContratoJuridicoGaranteSolidario },
    
    props: {
        id: {
            type: Number,
            required: false
        }
    },
    
    data() {
        return {
            dados: {
                contrato: {}
            },
            campoInvalido: {},
            contratoConsulta: {},
            padroesContratoFiltro: [],
            padroesContrato: [
                { codigo: "PROPRIO", descricao: "Próprio" },
                { codigo: "CLIENTE", descricao: "Cliente" }
            ],
            situacaoFiltro: [],
            situacoes: [
                { codigo: "PENDENTE", descricao: "Pendente" },
                { codigo: "FINALIZADO", descricao: "Finalizado" }
            ],
            moedaFiltro: [],
            moedas: [
                { codigo: "REAL", descricao: "Real" },
                { codigo: "DOLLAR", descricao: "Dólar" }
            ],
            simOuNaoFiltro: [],
            simOuNao: [
                { valor: true, descricao: "Sim" },
                { valor: false, descricao: "Não" }
            ]
        }
    },

    methods: {
        voltar() {
            this.$router.push(`/contratos/juridico`);
        },

        pesquisarContrato(filtros) {
            filtros.semContratoJuridico = true;
            ContratoCompraVendaService.buscarComPaginacao(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.contratoConsulta = data;
                    }
                })
                .catch(error => {
                    this.contratoConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar os contratos.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarContrato(codigo) {
            if(codigo == undefined || codigo == null) {
                this.dados.contrato = {};
                return;
            }

            ContratoCompraVendaService.buscarPorContratoInterno(codigo)
                .then(({ data }) => {
                    this.dados.contrato["id"] = data.id;
                    this.dados.contrato["nome"] = data.comprador.nome;
                    this.dados.contrato["contratoInternoId"] = data.contratoInternoId;
                    this.dados["codigoCliente"] = data.comprador.codigo;
                    this.dados["modalidade"] = data.modalidadePrecificacao === "A_FIXAR" ? "Preço A Fixar" : "Preço Fixo"
                })
                .catch(error => {
                    this.dados.contrato = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar contrato.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if(!!message) {
                            toast["detail"] = message;
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        buscarPadroesContrato(event) {
            if (!event.query.trim().length) {
                this.padroesContratoFiltro = [...this.padroesContrato];
            } else {
                this.padroesContratoFiltro = this.padroesContrato.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        buscarSituacoes(event) {
            if (!event.query.trim().length) {
                this.situacaoFiltro = [...this.situacoes];
            } else {
                this.situacaoFiltro = this.situacoes.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        buscarMoedas(event) {
            if (!event.query.trim().length) {
                this.moedaFiltro = [...this.moedas];
            } else {
                this.moedaFiltro = this.moedas.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        buscarSimOuNao(event) {
            if (!event.query.trim().length) {
                this.simOuNaoFiltro = [...this.simOuNao];
            } else {
                this.simOuNaoFiltro = this.simOuNao.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        montarJsonTabela(lista) {
            return lista.filter(item => { return !!item.nome && !!item.cpf && !!item.tipo })
                        .map(item => {
                            const avalista = {};
                            avalista["nome"] = item.nome;
                            avalista["cpf"] = item.cpf;
                            
                            if(!!item.tipo) {
                                avalista["tipo"] = item.tipo.codigo;
                            }

                            if(!!item.assinatura) {
                                avalista["assinatura"] = item.assinatura.codigo;
                            }

                            const fielDepositario = {}
                            fielDepositario["id"] = item.id;
                            fielDepositario["avalista"] = avalista;

                            return fielDepositario;
                        });
        },

        montarJson() {
            const obj = {};
            obj["id"] = this.id;
            obj["contratoCompraVendaId"] = this.dados.contrato.id;
            obj["codigoCliente"] = this.dados.codigoCliente;
            obj["modalidadePrecificacao"] = this.dados.modalidade === "Preço A Fixar" ? "A_FIXAR" : "FIXO";
            obj["moeda"] = !!this.dados.moeda ? this.dados.moeda.codigo : undefined;
            obj["padraoContrato"] = this.dados.padraoContrato.codigo;
            obj["emiteParaCadaFixacao"] = !!this.dados.emiteContratoParaCadaFixacao ? this.dados.emiteContratoParaCadaFixacao.valor : false;
            obj["contratoGerado"] = !!this.dados.contratoGerado ? this.dados.contratoGerado.valor : false;
            obj["situacaoContrato"] = !!this.dados.situacao ? this.dados.situacao.codigo : undefined;
            obj["descCobrancaArmazenagem"] = this.dados.descritivoCobrancaArmazenagem;
            obj["descConversaoMoeda"] = this.dados.descritivoConversaoMoeda;
            obj["descOutrasCondicoes"] = this.dados.outrasCondicoes;
            obj["descPreco"] = this.dados.descritivoPreco;
            obj["descVencimento"] = this.dados.descritivoVencimento;
            obj["descFixacao"] = this.dados.descritivoFixacao;

            if(!!this.dados.tipo) {
                const condicaoPagamento = {};
                condicaoPagamento["tipoCondicaoPagamento"] = this.dados.tipo;
                
                if(this.dados.tipo === 'DIAS_EMBARQUE' || this.dados.tipo === 'DIAS_DESEMBARQUE' || this.dados.tipo === 'DIAS_FIXACAO') {
                    condicaoPagamento["numeroDiasPagamento"] = this.dados.nroDias;
                }
                else if(this.dados.tipo === 'OUTROS') {
                    condicaoPagamento["outrosDadosPagamento"] = this.dados.outros;
                }
                else if(this.dados.tipo === 'FIXAO_INTEGRAL_NO_DIA') {
                    condicaoPagamento["dataVencimentoPagamento"] = this.dados.dataVencimento;
                }

                obj["condicaoPagamento"] = condicaoPagamento;
            }

            obj["listaDeFielDepositario"] = this.montarJsonTabela(this.dados.fielDepositario);
            obj["listaDeGaranteSolidario"] = this.montarJsonTabela(this.dados.GaranteSolidario);

            return obj;
        },

        validarCampos() {
            let campos = [];

            if(!this.dados.contrato.id || !this.dados.codigoCliente) {
                campos.push("Contrato");
                this.campoInvalido.contrato = true;
            }
            else {
                this.campoInvalido.contrato = false;
            }

            if(!this.dados.padraoContrato) {
                campos.push("Padrão Contrato");
                this.campoInvalido.padraoContrato = true;
            }
            else {
                this.campoInvalido.padraoContrato = false;
            }

            if(!this.dados.emiteContratoParaCadaFixacao) {
                campos.push("Emite Contrato para cada Fixação");
                this.campoInvalido.emiteContratoParaCadaFixacao = true;
            }
            else {
                this.campoInvalido.emiteContratoParaCadaFixacao = false;
            }

            if(campos.length > 0) {
                let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                if(campos.length > 1) {
                    mensagem = "Por favor, preencher os campos: ";
                    mensagem += campos.join(", ");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                }

                this.$toast.add({
                    severity: 'warn',
                    summary: 'Não foi possível salvar o contrato jurídico.',
                    detail: mensagem, 
                    life: 15000
                });
            }

            return campos.length === 0;
        },

        salvar() {
            if(!this.validarCampos()) return;
            const dados = this.montarJson();
            ContratoJuridicoService.salvar(dados)
                .then(({ data }) => {
                    if(!!data) {
                        const detalhe = 'O contrato jurídico código ' + data.id +' foi salvo com sucesso.';
                        StorageService.setMensagemToast("CONTRATO-JURIDICO", 'success', 'Contrato jurídico salvo', detalhe, true);
                        this.voltar();
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível salvar o contrato jurídico.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        montarTabela(lista) {
            return lista.map(item => {
                    const _item = {};

                    _item["id"] = item.id;
                    _item["nome"] = item.avalista.nome;
                    _item["cpf"] = item.avalista.cpf;

                    if(item.avalista.tipo == "COOPERATIVA") {
                        _item["tipo"] = { codigo: "COOPERATIVA", descricao: "Cooperativa" };
                    }
                    else if(item.avalista.tipo == "CLIENTE") {
                        _item["tipo"] = { codigo: "CLIENTE", descricao: "Cliente" };
                    }

                    if(item.avalista.assinatura == "PRESIDENTE") {
                        _item["assinatura"] = { codigo: "PRESIDENTE", descricao: "Presidente" };
                    }

                    return _item;
                });
        },

        preencherForm(data) {
            const dados = {};
            dados["id"] = this.id;
            
            const contrato = {};
            contrato["id"] = data.contratoCompraVenda.id;
            contrato["nome"] = data.contratoCompraVenda.comprador.nome;
            contrato["contratoInternoId"] = data.contratoInternoId;
            dados["contrato"] = contrato;

            dados["codigoCliente"] = data.contratoCompraVenda.comprador.codigo
            dados["modalidade"] = data.modalidadePrecificacao === "A_FIXAR" ? "Preço A Fixar" : "Preço Fixo";
            dados["moeda"] = this.moedas.find(item => { return item.codigo === data.moeda });
            dados["padraoContrato"] = this.padroesContrato.find(item => { return item.codigo === data.padraoContrato });
            dados["emiteContratoParaCadaFixacao"] = this.simOuNao.find(item => { return item.valor === data.emiteParaCadaFixacao });
            dados["contratoGerado"] = this.simOuNao.find(item => { return item.valor === data.contratoGerado });
            dados["situacao"] = this.situacoes.find(item => { return item.codigo === data.situacaoContrato });
            dados["descritivoCobrancaArmazenagem"] = data.descCobrancaArmazenagem;
            dados["descritivoConversaoMoeda"] = data.descConversaoMoeda;
            dados["outrasCondicoes"] = data.descOutrasCondicoes;
            dados["descritivoPreco"] = data.descPreco;
            dados["descritivoVencimento"] = data.descVencimento;
            dados["descritivoFixacao"] = data.descFixacao;

            if(!!data.condicaoPagamento) {
                dados["tipo"] = data.condicaoPagamento.tipoCondicaoPagamento;
                dados["nroDias"] = data.condicaoPagamento.numeroDiasPagamento;
                dados["outros"] = data.condicaoPagamento.outrosDadosPagamento;
                dados["dataVencimento"] = data.condicaoPagamento.dataVencimentoPagamento;
            }

            dados.fielDepositario = this.montarTabela(data.listaDeFielDepositario);
            dados.GaranteSolidario = this.montarTabela(data.listaDeGaranteSolidario);

            this.dados = dados;
        }
    },

    mounted(){
        if(!!this.id) {
            ContratoJuridicoService.buscarPorId(this.id)
                .then(({ data }) => {
                    this.preencherForm(data);
                })
                .catch(error => {
                    this.dados = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar os dados do contrato jurídico.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message, exception } = error.response.data;

                        if(!!exception) {
                            toast["detail"] = exception;
                        }
                    }

                    this.$toast.add(toast);
                });
        }
    }
}
</script>

<style scoped>
    .borda-personalizada {
        border: 1px solid #CCC;
        border-radius: 3px;
        padding: 1rem;
    }
</style>
