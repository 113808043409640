<template>    
    <ContratoJuridicoForm v-if="isCadastrar()" :id="id" />
    <ContratoJuridicoList v-if="!isCadastrar()" />
</template>

<script>
import ContratoJuridicoList from '../../components/cadastro/ContratoJuridicoList.vue';
import ContratoJuridicoForm from '../../components/cadastro/ContratoJuridicoForm.vue'

export default {
    name: 'ContratoPage',
    components: { ContratoJuridicoForm, ContratoJuridicoList },

    data() {
        return {
            isCadastro: false,
            isEditar: false,
            id: null
        }
    },

    created() {
        this.validarRouter();
    },

    updated() {
        this.validarRouter();
    },

    methods: {
        editar(id) {               
            if(id) {
                this.id = id;
                this.isEditar = true;
            }
            else {
                this.id = null;
                this.isEditar = false;
            }
        },

        isCadastrar() {
            return this.isCadastro || this.isEditar;
        },

        validarRouter() {
            const routerName = this.$route.name
            if(routerName === 'contrato-juridico-cadastro') {
                this.isCadastro = true;
            }
            else if(routerName === 'contrato-juridico-editar') {
                const id = parseInt(this.$route.params.id);
                this.editar(id);
            }
            else {
                this.isCadastro = false;
                this.isEditar = false;
                this.id = null;
            }
        }
    }
}
</script>