<template>
    <DataTable
        editMode="row"
        v-model:editingRows="editingRows"
        :value="dados.fielDepositario" showGridlines class="mt-3">
        <template #header>
            <div class="flex align-items-center justify-content-between">
                <strong style="font-size: 14px;">Fiel Depositário</strong>
            </div>
        </template>

        <template #empty>
            Nenhum registro.
        </template>

        <Column field="nome" header="Nome">
            <template #editor="{ data, field }">
                <InputText 
                    v-model="data[field]"
                    class="w-full"
                    :class="{ 'p-invalid': campoInvalido.FielDepositarioNome }"
                />
            </template>
        </Column>
        
        <Column field="cpf" header="CPF" style="width: 10rem;">
            <template #editor="{ data, field }">
                <InputMask 
                    mask="999.999.999-99"
                    placeholder="999.999.999-99"
                    v-model="data[field]"
                    class="w-full"
                    :class="{ 'p-invalid': campoInvalido.FielDepositarioCpf }"
                />
            </template>
        </Column>
        
        <Column field="tipo" header="Tipo" style="width: 10rem;">
            <template #body="{ data, field }">
                {{ data[field]?.descricao }}
            </template>

            <template #editor="{ data, field }">
                <AutoComplete
                    placeholder="Selecione..." 
                    dropdown
                    v-model="data[field]"
                    field="descricao"
                    class="w-full"
                    :suggestions="tipoFiltro"
                    :forceSelection="true"
                    :class="{ 'p-invalid': campoInvalido.FielDepositarioTipo }"
                    @complete="buscarTipo"
                    @item-select="tipoSelecionadoAction(data)"
                />
            </template>
        </Column>
        
        <Column field="assinatura" header="Assinatura" style="width: 10rem;">
            <template #body="{ data, field }">
                {{ data[field]?.descricao }}
            </template>

            <template #editor="{ data, field }">
                <AutoComplete
                    placeholder="Selecione..." 
                    dropdown
                    v-model="data[field]"
                    field="descricao"
                    class="w-full"
                    :class="{ 'p-invalid': campoInvalido.FielDepositarioAssinatura }"
                    :disabled="!!data.tipo && data.tipo.codigo !== 'COOPERATIVA'"
                    :hidden="!!data.tipo && data.tipo.codigo !== 'COOPERATIVA'"
                    :suggestions="assinaturaFiltro"
                    :forceSelection="true"
                    @complete="buscarAssinatura"
                />
            </template>
        </Column>

        <Column header="Ações" style="width:9rem;" class="centralizar-titulo-tabela"  >
            <template #body="{ data, index }">
                <div class="flex align-items-center justify-content-center">
                    <Button
                        class="p-button-rounded p-button-info flex-none mr-2"
                        icon="pi pi-plus"
                        aria-label="Novo"
                        @click="editar(data)"
                        v-if="!data.nome"
                    ></Button>
                    <Button
                        class="p-button-rounded p-button-info flex-none mr-2"
                        icon="pi pi-pencil"
                        aria-label="Editar"
                        @click="editar(data)"
                        v-if="data.nome"
                    ></Button>
                    <Button 
                        class="p-button-rounded p-button-danger flex-none mr-2"
                        icon="pi pi-trash"
                        aria-label="Excluir"
                        @click="excluir(index)"
                        v-if="data.nome"
                    ></Button>
                </div>
            </template>

            <template #editor="{ data, index }">
                <div class="flex align-items-center justify-content-center">
                    <Button
                        class="p-button-rounded p-button-info flex-none mr-2"
                        icon="pi pi-save"
                        aria-label="Editar"
                        @click="salvar(data, index)"
                    ></Button>
                    <Button
                        class="p-button-rounded p-button-danger flex-none mr-2"
                        icon="pi pi-times"
                        aria-label="Editar"
                        @click="cancelar()"
                    ></Button>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
export default {
    props: {
        dados: {
            type: Object,
            required: true
        },
        
        campoInvalido: {
            type: Object,
            required: true
        }
    },
    
    data() {
        return {
            editingRows: [],

            tipoFiltro: [],
            tipos: [
                {
                    codigo: "COOPERATIVA",
                    descricao: "Cooperativa"
                },
                {
                    codigo: "CLIENTE",
                    descricao: "Cliente"
                }
            ],

            assinaturaFiltro: [],
            assinaturas: [
                {
                    codigo: "PRESIDENTE",
                    descricao: "Presidente"
                }
            ]
        }
    },

    created() {
        this.setup();
    },

    updated() {
        this.setup();
    },

    methods: {
        setup() {
            if(!this.dados.fielDepositario) {
                this.dados.fielDepositario = [];
            }

            this.adicionarLinha();
        },

        editar(registro) {
            const registroAux = Object.assign({}, registro);
            this.editingRows = [registroAux];
        },

        salvar(registro, indice) {
            if(!registro) return;

            const campos = [];
            if(!registro.nome) {
                campos.push("Nome");
                this.campoInvalido.FielDepositarioNome = true;
            }
            else {
                this.campoInvalido.FielDepositarioNome = false;
            }

            if(!registro.cpf) {
                campos.push("CPF");
                this.campoInvalido.FielDepositarioCpf = true;
            }
            else {
                this.campoInvalido.FielDepositarioCpf = false;
            }

            if(!registro.tipo) {
                campos.push("Tipo");
                this.campoInvalido.FielDepositarioTipo = true;
            }
            else {
                this.campoInvalido.FielDepositarioTipo = false;

                if(registro.tipo.codigo === "COOPERATIVA" && !registro.assinatura) {
                    campos.push("Assinatura");
                    this.campoInvalido.FielDepositarioAssinatura = true;
                }
                else {
                    this.campoInvalido.FielDepositarioAssinatura = false;
                }
            }

            if(campos.length > 0) {
                const toast = {
                    severity: 'warn',
                    summary: 'Fiel Depositário',
                    detail: `Por favor, preencher o campo ${ campos[0] }`, 
                    life: 7500
                }

                if(campos.length > 1) {
                    toast.detail = "Por favor, preencher os campos: ";
                    toast.detail += campos.join(", ");

                    const indice = toast.detail.lastIndexOf(", ");
                    toast.detail = `${ toast.detail.substring(0, indice) } e ${ toast.detail.substring(indice + 1, toast.detail.length) }.`;
                }

                this.$toast.add(toast);
                return;
            }


            this.dados.fielDepositario[indice] = registro;
            this.editingRows = [];
            this.adicionarLinha();
        },

        excluir(indice) {
            this.dados.fielDepositario.splice(indice, 1);
            this.adicionarLinha();
        },

        cancelar() {
            if(!this.editingRows && this.editingRows.length <= 0) return;
            this.editingRows = [];
            this.adicionarLinha();
            this.campoInvalido.FielDepositarioNome = false;
            this.campoInvalido.FielDepositarioCpf = false;
            this.campoInvalido.FielDepositarioTipo = false;
            this.campoInvalido.FielDepositarioAssinatura = false;
        },

        adicionarLinha() {
            const indice = this.dados.fielDepositario.findIndex(item => {
                return !item.nome || !item.cpf || !item.tipo;
            });

            if(indice < 0) {
                this.dados.fielDepositario.push({});
            }
        },
        
        buscarTipo(event) {
            if (!event.query.trim().length) {
                this.tipoFiltro = [...this.tipos];
            } else {
                this.tipoFiltro = this.tipos.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        tipoSelecionadoAction(dados) {
            dados["assinatura"] = undefined;
        },

        buscarAssinatura(event) {
            if (!event.query.trim().length) {
                this.assinaturaFiltro = [...this.assinaturas];
            } else {
                this.assinaturaFiltro = this.assinaturas.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
