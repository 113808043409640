import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class ContratoJuridicoService {
    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/contratojuridico`, dados, config);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/contratojuridico/buscar-com-paginacao`, { params });
    }

    buscarPorId(id) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/contratojuridico/${ id }`);
    }

    validarDocumento(id){
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/contratojuridico-doc/${ id }`);
    }

    gerarDocumento(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/contratojuridico-doc/${ id }`, {}, {responseType:'arraybuffer'});
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/contratojuridico/inativar/${ id }`);
    }
    
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/contratojuridico/ativar/${ id }`);
    }
}

export default new ContratoJuridicoService();
