<template>
    <div class="card">
        <div class="flex align-items-center">
            <img src="../../assets/img/contrato.png" style="width: 2.3rem; margin-right: 0.5rem;"/>
           <h3 style="margin:0px 5px;">Contrato Jurídico</h3>
        </div>

        <hr />

        <DataTable 
            responsiveLayout="stack"
            :value="data"
            v-model:selection="selecionado"
            selectionMode="single"
            showGridlines="true"
            @sort="sort($event)">

            <template #header>
                <div class="grid p-fluid formgrid align-items-center">
                    <div class="col-12 lg:col-1" style="width:9rem">
                      <div class="field">
                        <label for="nr-ci">
                          <strong>Contrato Jurídico</strong>
                        </label>
                        <InputText id="nr-contrato" class="w-full" v-model="filter.contratoJuridico" placeholder="Nº contrato"/>
                      </div>
                    </div>

                  <div class="col-12 lg:col-1">
                    <div class="field">
                      <label for="nr-ci">
                        <strong>Contrato Interno</strong>
                      </label>
                      <InputNumber id="nr-ci" class="w-full" v-model="filter.idContrato" placeholder="Nº contrato"/>
                    </div>
                  </div>

                  <div class="col-12 lg:col-3">
                    <div class="field">
                      <label>
                        <strong>Cliente</strong>
                      </label>
                      <ProdutorFiltro
                          tituloModal="Buscar Comprador"
                          :disabled="false"
                          :campoObrigatorio="true"
                          :selecionado="filter.compradorSelecionado"
                          :paginacao="compradorConsulta"
                          @pesquisar="pesquisarComprador"
                          @selecionado="selecionarComprador"
                      />
                    </div>
                  </div>

                    <div class="field mr-2">
                        <label>
                            <strong>Período</strong>
                        </label>

                        <div class="flex align-items-center">
                            <div class="overflow-hidden" style="width:6rem;">
                                <Calendar
                                    class="w-full"
                                    v-model="periodoInicio"
                                    autocomplete="off"
                                    dateFormat="dd/mm/y"
                                />
                            </div>

                            <strong class="ml-2 mr-2">à</strong>
                            
                            <div class="overflow-hidden" style="width:6rem;">
                                <div class="p-inputgroup">
                                    <Calendar
                                        class="w-full"
                                        v-model="periodoFim"
                                        autocomplete="off"
                                        dateFormat="dd/mm/y"
                                        :minDate="periodoInicio"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                style="margin-top:0.5rem"
                                @click="carregarDados()"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px; margin-top: 0.5rem"
                                @click="limparFiltro()"
                            ></Button>
                          <Button
                              v-if="filter.situacao == 'ATIVO'"
                              type="button"
                              icon="pi pi-ban"
                              label="Listar inativos"
                              class="p-button-outlined p-button-danger mr-2 mt-2"
                              @click="filter.situacao = 'INATIVO'; carregarDados()"
                          ></Button>

                          <Button
                              v-if="filter.situacao == 'INATIVO'"
                              type="button"
                              icon="pi pi-check-circle"
                              label="Listar ativos"
                              class="p-button-outlined mr-2 mt-2"
                              @click="filter.situacao = 'ATIVO'; carregarDados()"
                          ></Button>

                          <Button
                              icon="pi pi-filter"
                              label="Filtro Avançado"
                              class="p-button-outlined"
                              style="min-width:150px; margin-top:0.5rem"
                              @click="contratoFiltroAvancadoDialog = true"
                          ></Button>

                          <Button style="margin-left:1rem"
                              label="Cadastrar"
                              icon="pi pi-plus"
                              class="p-button-info mt-2 mr-2"
                              @click="cadastrar()"
                          ></Button>

                        </div>
                    </div>

                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="contratoJuridico" header="Nº Contrato" />
            <Column field="corretora" header="Corretora Representante">
                <template #body="{ data, field }">
                    {{ data[field].codigoCliente }} - {{ data[field].nome }}
                </template>
            </Column>
            <Column field="padraoContrato" header="Padrão Contrato">
                <template #body="{ data, field }">
                    {{ data[field] == "PROPRIO" ? "Próprio" : "Cliente" }}
                </template>
            </Column>
            <Column field="contratoInternoId" header="Nro. Cont. Interno" />
            <Column field="contratoCorretora" header="Nro. Cont. Corretora" />
            
            <Column field="dataCadastro" header="Dt. Emissão">
                <template #body="{ data, field }">
                    {{ formatDateCustom(data[field], 'DD/MM/YYYY') }}
                </template>
            </Column>
            
            <Column field="dataContrato" header="Dt. Cont. Rep.">
                <template #body="{ data, field }">
                    {{ formatDateCustom(data[field], 'DD/MM/YYYY') }}
                </template>
            </Column>
            
            <Column field="contratoGerado" header="Contr. Gerado">
                <template #body="{ data, field }">
                    {{ data[field] ? "Sim" : "Não" }}
                </template>
            </Column>

            <Column field="situacaoContrato" header="Situação">
                <template #body="{ data, field }">
                    <span v-if="data[field] === 'PENDENTE'">Pendente</span>
                    <span v-if="data[field] === 'FINALIZADO'">Finalizado</span>
                </template>
            </Column>

            <Column header="Ações" style="width:13rem;" class="centralizar-titulo-tabela">
                <template #body="{ data }">
                    <div class="flex-none">

                        <Button 
                            title="Visualizar histórico"
                            icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"
                        ></Button>
                        
                        <Button
                            v-if="data.ativo"
                            title="Editar"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none mr-2"
                            @click="editar(data.id)"
                        ></Button>

                        <Button
                            v-if="data.ativo"
                            title="Gerar Documento"
                            icon="pi pi-file"
                            class="p-button-rounded p-button-success flex-none mr-2"
                            @click="gerarDocumento(data.contratoInternoId)"
                        ></Button>

                        <Button 
                            v-if="data.ativo"
                            title="Inativar"
                            class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>

                        <Button 
                            v-if="!data.ativo"
                            title="Ativar"
                            class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator
            v-model:first="primeiraLinha"
            :rows="qtdRegistroPorPagina"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage" />

    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ selecionado.ativo ? 'inativar' : 'ativar' }}</strong> o contrato do CI: <strong>{{ selecionado.contratoInternoId }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <ContratoFiltroAvancado
        :visivel="contratoFiltroAvancadoDialog"
        :filter="filter"
        @filtrar="filtrar">
    </ContratoFiltroAvancado>

    <ModalHistoricoGenerico
        codigoPagina="CJ"
        :id="selecionado.id"
        :descricao="descricaoHistoricoDialog"
        :visivel="historicoDialog"
        @fechar="ocultarHistorico()"
    />
</template>

<script>
import ContratoJuridicoService from '../../service/ContratoJuridicoService';
import ModalHistoricoGenerico from '../ModalHistoricoGenerico.vue';
import StorageService from '../../service/StorageService';
import Formatacao from '../../utilities/Formatacao';
import ProdutorFiltro from "@/components/cadastro/ProdutorFiltro.vue";
import ProdutorService from "@/service/ProdutorService";
import ContratoFiltroAvancado from "@/components/zoom/ContratoFiltroAvancado.vue";

export default {
    name: 'ContratoJuridicoList',
    components: {ContratoFiltroAvancado, ProdutorFiltro, ModalHistoricoGenerico },
    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,  

            data: [],

            filter: {},
            ordenar: 'id,asc',
            periodoInicio: null,
            periodoFim: null,
            
            selecionado: {},
            historicoDialog: false,
            ativarOuInativarDialog: false,
            descricaoHistoricoDialog: null,
            campoInvalido: {},
            contratoConsulta: {},
            compradorConsulta: {},
            contratoFiltroAvancadoDialog: false,

            storange: StorageService.getControlePagina("CONTRATO-JURIDICO"),
            storangeMessage: StorageService.getMensagemToast("CONTRATO-JURIDICO")    
        }
    },

    created() {
        //verifica se o filtro está vazio
        this.filter.situacao = "ATIVO";

        if(this.storange.default == undefined){
            this.filter = this.storange.filtros;
        }

        this.filter["compradorSelecionado"] = {codProdutor:""};
        this.pagina = this.storange.numero;
        this.primeiraLinha = this.storange.firstRow;
        this.qtdRegistroPorPagina = this.storange.qtdRegistro; 
        this.carregarDados();
        this.exibirMensagemToast();
    },

    methods: {

        exibirMensagemToast() {
            if (this.storangeMessage.active){
                this.$toast.add({
                    severity: this.storangeMessage.severity,
                    summary: this.storangeMessage.summary,
                    detail: this.storangeMessage.detail, 
                    life: 7500
                });
                StorageService.setMensagemToast("CONTRATO-JURIDICO", 'info', '', '', false);
            }
        },

        cadastrar() {
            this.$router.push(`/contrato/juridico/cadastro`);
        },

        editar(contratoId) {                
            this.$router.push(`/contrato/juridico/editar/${ contratoId }`);
        },

        gerarDocumento(contratoInternoId) {
            ContratoJuridicoService.validarDocumento(contratoInternoId)
            .then(response => {
                if (!response.data.message) {
                    ContratoJuridicoService.gerarDocumento(contratoInternoId)
                        .then(response => {
                            const blob = new Blob([response.data], { type: 'application/pdf' });
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'arquivo.pdf');
                            document.body.appendChild(link);
                            link.click();
                            window.URL.revokeObjectURL(url);
                            
                            this.$toast.add({
                                severity:'success',
                                detail:`Download do arquivo realizado com sucesso!`, 
                                life: 3000
                            }); 
                        });

                } else {    
                    this.$toast.add({
                        severity:'warn',
                        summary: response.data.message,
                        detail:response.data.exception, 
                        life: 3000
                    });
                }
            }).catch(error => {
                const toast = {
                    severity: 'error',
                    summary: `Documento do contrato jurídico.`,
                    detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                    life: 7500
                };

                if(!!error && !!error.response && !!error.response.data) {
                    const { message, exception } = error.response.data;

                    toast["summary"] = message;
                    toast["detail"] = exception;
                }

                this.$toast.add(toast);
            });
        },

        filtrar(filtro){
          this.contratoFiltroAvancadoDialog = false;
          if (!!filtro) {this.carregarDados(filtro);}
        },

        carregarDados(filtros) {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;
            this.filter["dataContratoInicio"] = undefined;
            this.filter["dataContratoFim"] = undefined;

            if(!!this.periodoInicio) {
                this.filter["dataContratoInicio"] = this.formatDateCustom(this.periodoInicio, "YYYY-MM-DD");
            }

            if(!!this.periodoFim) {
                this.filter["dataContratoFim"] = this.formatDateCustom(this.periodoFim, "YYYY-MM-DD");
            }

            this.filter["contratoInternoId"] = this.filter.idContrato;

            if(!!filtros){
              this.filter["contratoJuridico"] = filtros.contratoJuridico;
              this.filter["contratoCorretora"] = filtros.contratoCorretora;
              this.filter["idCorretora"] = filtros.corretoraId;
              this.filter["periodoInicio"] = filtros.periodoInicio;
              this.filter["periodoFim"] = filtros.periodoFim;
              this.filter["idProduto"] = filtros.idProduto;
            }

            if (!!this.filter.compradorSelecionado){
              this.filter["nomeCliente"] = this.filter.compradorSelecionado.nome;
            }

            ContratoJuridicoService.buscarComPaginacao(this.filter)
                .then(({ data }) => {
                    if(data) {
                        this.data = data.content;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Falha ao buscar contratos jurídico',
                        detail:`Falha não mapeada.`, 
                        life: 7500
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });

            StorageService.setControlePaginaFilter("CONTRATO-JURIDICO", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);               
        },

        pesquisarComprador(filtros) {
          ProdutorService.pesquisarProdutor(filtros)
              .then(({ data }) => {
                if(data) {
                  this.compradorConsulta = data;
                }
              })
              .catch(error => {
                this.compradorConsulta = {}

                const toast = {
                  severity: 'error',
                  summary: 'Falha ao carregar os compradores.',
                  detail: error,
                  life: 7500
                }

                if(!!error && !!error.response && !!error.response.data) {
                  const { message } = error.response.data;
                  toast["detail"] = message;
                  toast["severity"] = 'warn';
                }

                this.$toast.add(toast);
              });
        },

        selecionarComprador(codProdutor) {
          if(codProdutor == undefined || codProdutor == null || codProdutor.trim() == "") {
            this.compradorSelecionado = {};
            this.filter.compradorSelecionado = {};
            return;
          }

          ProdutorService.buscarPorCodProdutorInfoContrato(codProdutor)
              .then(({ data }) => {
                if(data) {
                  this.compradorSelecionado = data;
                  this.filter.compradorSelecionado = data;

                }
              })
              .catch(error => {
                this.compradorSelecionado = {};
                this.filter.compradorSelecionado = {};


                const toast = {
                  severity: 'error',
                  summary: `Falha ao buscar o comprador.`,
                  detail: error,
                  life: 7500
                }

                if(!!error && !!error.response && !!error.response.data) {
                  const { message } = error.response.data;
                  if(!!message) {
                    toast["detail"] = message.replace("produtor", "comprador");
                  }
                  toast["severity"] = 'warn';
                }

                this.$toast.add(toast);
              });
        },

        limparFiltro() {
            this.filter["descricao"] = undefined
            this.filter["dataContratoFim"] = undefined;
            this.filter["dataContratoInicio"] = undefined;
            this.filter["contratoJuridico"] = undefined;
            this.filter["produtoId"] = undefined;
            this.filter["idProduto"] = undefined;
            this.filter["periodoInicio"] = undefined;
            this.filter["periodoFim"] = undefined;
            this.filter["contratoInternoId"] = null;
            this.filter["idContrato"] = null;
            this.filter["idCorretora"] = undefined;
            this.filter["corretoraId"] = undefined;
            this.filter["compradorSelecionado"] = {codProdutor:""};
            this.filter["idProduto"] = undefined;
            this.filter["contratoCorretora"] = undefined;

            this.periodoInicio = null;
            this.periodoFim = null;
            this.contratoConsulta = {};

            this.carregarDados();
        },

        confirmarAtivarOuInativar(contrato) {
            this.selecionado = contrato;
            this.ativarOuInativarDialog = true;
        },

        visualizarHistorico(contrato) {
            this.descricaoHistoricoDialog = "Contrato Interno: "+ contrato.contratoInternoId;
            this.historicoDialog = true;
            this.selecionado = contrato;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.selecionado = {};
        },

        ativarOuInativar() {
            if(this.selecionado.ativo) {
                ContratoJuridicoService.inativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativado',
                            detail:`O contrato jurídico foi inativado com sucesso.`, 
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao inativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                ContratoJuridicoService.ativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`O contrato jurídico foi ativado com sucesso.`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao ativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }

            this.ativarOuInativarDialog = false;
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows; 
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${ sortField },${sortOrder}`;
            this.carregarDados();
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
